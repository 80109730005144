<script>
	import Alert from './view/Alert.svelte';
	import Config from './view/Config.svelte';
	import Search from './view/Search.svelte';
	import Nav from './view/Nav.svelte';
	import { google_drive } from './inc/google-drive.js';
	import {
		configSyncMessageShow,
		configSyncMessageType,
		configSyncMessage
	} from './store/config-stores.js';

	// Super cheap hash routing
	let hash = document.location.hash;
	function updateHash() {
		hash = document.location.hash;
	}
	window.onhashchange = updateHash;

	// Initialize Google Drive; prep for dependancies loaded below
	google_drive.init();
</script>

<div class="container">
	{#if hash === '#config'}
		<Nav {hash} />
		<main>
			<Config />
		</main>
	{:else}
		<Nav {hash} />
		<main>
			<Search />
		</main>
	{/if}

	{#if $configSyncMessageShow}
		<Alert type={$configSyncMessageType} message={$configSyncMessage} />
	{/if}
</div>

<svelte:head>
	<script
		async
		defer
		src="https://apis.google.com/js/api.js"
		onerror="console.err"></script>

	<script
		async
		defer
		src="https://accounts.google.com/gsi/client"
		onerror="console.err"></script>

	<!-- JSON-LD markup generated by Google Structured Data Markup Helper. -->
	<script type="application/ld+json">
      {
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "name": "Quixer",
        "url": "https://quixer.onl/",
        "author": {
          "@type": "Person",
          "name": "Chris Putnam"
        },
        "datePublished": CURRENT_DATE_STAMP,
        "applicationCategory": "Productivity"
      }
	</script>
</svelte:head>

<style>
	.container {
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: flex-start;
		align-items: flex-start;
	}
	main {
		width: auto;
		max-width: none;
	}
</style>
